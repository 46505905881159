import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import { MainColour } from '../../utils/variables';

export default function HeaderMessage() {
  const data = useStaticQuery(graphql`
    query HeaderMessageQuery {
      wp {
        ...HeaderText
      }
    }
  `);
  const message = data.wp.options.awwSettings.headerMessage;
  return (
    message && (
      <strong
        style={{
          textTransform: `uppercase`,
          fontFamily: `Corinthian Bold, sans-serif`,
          fontSize: `36px`,
        }}
      >
        {parse(message.replace('<span', `<span style="color:${MainColour}"`))}
      </strong>
    )
  );
}
